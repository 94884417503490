import * as Sentry from '@sentry/vue'
import setupRouter from 'frontend/_setup/setupRouter/setupRouter.js'

const initGlitchtip = app => {
  Sentry.init({
    app,
    dsn: 'https://e1f117f8a8af46a1b78b7cabdc88abf0@errorcatcher.enginearch.com/2',
    environment: import.meta.env.MODE,
    release: document?.body?.getAttribute('release') || undefined,
    trackComponents: true,
    enabled: import.meta.env.MODE != 'development',
    ignoreErrors: [
      'Request failed with status code 401',
      'Request failed with status code 403',
      'Request failed with status code 412',
      'Request failed with status code 413',
      'Request failed with status code 490',
      'Request failed with status code 491',
      'Request failed with status code 495',
      'Request failed with status code 500',
      'Request failed with status code 422',
      'Request failed with status code 409',
      'Request failed with status code 400',
      'Request failed with status code 404',
      'Network Error',
      'Form contains errors',
      /.*Failed to fetch dynamically imported module.*/gi,
      /.*Failed to update a ServiceWorker for scope.*/gi,
      /.*Unable to preload CSS for \/vite\/.*/gi,
    ],
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(setupRouter),
      }),
      new Sentry.Replay(),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    // tracesSampleRate: 0.005,
    tracesSampleRate: 0,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    autoSessionTracking: false,
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1.0,
    sendClientReports: false,
  })
}

export default initGlitchtip
