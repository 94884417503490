import { defineAsyncComponent, shallowRef } from 'vue'

const FORM_MODALS = {
  Impersonate: defineAsyncComponent(() => import('frontend/common/impersonate-modal.vue')),
  EventOrganizationUserRole: defineAsyncComponent(() =>
    import('frontend/event-organization-users/assign-event-organization-users-role-modal.vue'),
  ),
  PopulateEventOrganizationUserRole: defineAsyncComponent(() =>
    import('frontend/event-organization-users/populate-event-organization-users-role-modal.vue'),
  ),
  RoleValuePreset: defineAsyncComponent(() =>
    import('frontend/role-value-presets/role-value-preset-form-modal.vue'),
  ),
  ClientCategory: defineAsyncComponent(() =>
    import('frontend/client-categories/client-category-form-modal.vue'),
  ),
  NotificationTemplate: defineAsyncComponent(() =>
    import('frontend/notification-templates/notification-template-form-modal.vue'),
  ),
  ReturningBookingDecision: defineAsyncComponent(() =>
    import('frontend/batch-onsite-requests/returning-booking-decision-modal.vue'),
  ),
  CustomColumns: defineAsyncComponent(() =>
    import('frontend/common/customizable-columns/custom-columns-modal.vue'),
  ),
  Name: defineAsyncComponent(() => import('frontend/common/name-modal.vue')),
  TransferGroupPeopleChoose: defineAsyncComponent(() =>
    import('frontend/transfer-groups/transfer-group-people-choose-modal.vue'),
  ),
  TransferGroupChangeAasmStateModal: defineAsyncComponent(() =>
    import('frontend/transfer-groups/transfer-group-change-aasm-state-modal.vue'),
  ),
  ActivityTransferChangeAasmStateModal: defineAsyncComponent(() =>
    import('frontend/activity-transfers/activity-transfer-change-aasm-state-modal.vue'),
  ),
  ShuttleTransferChangeAasmStateModal: defineAsyncComponent(() =>
    import('frontend/shuttle-transfers/shuttle-transfer-change-aasm-state-modal.vue'),
  ),
  ShiftActionChangeAasmStateModal: defineAsyncComponent(() =>
    import('frontend/shift-actions/shift-action-change-aasm-state-modal.vue'),
  ),
  BatchOnsiteRequest: defineAsyncComponent(() =>
    import('frontend/batch-onsite-requests/batch-onsite-request-form-modal.vue'),
  ),
  TravelTimeModifier: defineAsyncComponent(() =>
    import('frontend/travel-time-modifiers/travel-time-modifier-form-modal.vue'),
  ),
  Client: defineAsyncComponent(() => import('frontend/clients/client-form-modal.vue')),
  ClientArchive: defineAsyncComponent(() => import('frontend/clients/client-archive-modal.vue')),
  ClientNonCompanion: defineAsyncComponent(() =>
    import('frontend/client-non-companions/client-non-companion-form-modal.vue'),
  ),
  Report: defineAsyncComponent(() => import('frontend/reports/report-form-modal.vue')),
  ShiftAction: defineAsyncComponent(() =>
    import('frontend/shift-actions/shift-action-form-modal.vue'),
  ),
  ShiftActionWithShift: defineAsyncComponent(() =>
    import('frontend/shift-actions/shift-action-with-shift-form-modal.vue'),
  ),
  Shift: defineAsyncComponent(() => import('frontend/shifts/shift-form-modal.vue')),
  BatchShift: defineAsyncComponent(() => import('frontend/shifts/batch-shift-form-modal.vue')),
  ShiftCopy: defineAsyncComponent(() => import('frontend/shifts/shift-copy-modal.vue')),
  ShiftReplaceVehicle: defineAsyncComponent(() =>
    import('frontend/shifts/shift-replace-vehicle-modal.vue'),
  ),
  ShiftReplaceDriver: defineAsyncComponent(() =>
    import('frontend/shifts/shift-replace-driver-modal.vue'),
  ),
  ShiftEmergencyReplaceDriver: defineAsyncComponent(() =>
    import('frontend/shifts/shift-emergency-replace-driver-modal.vue'),
  ),
  ShiftBulkCreation: defineAsyncComponent(() =>
    import('frontend/shifts/shift-bulk-creation-modal.vue'),
  ),
  ClientGroup: defineAsyncComponent(() =>
    import('frontend/client-groups/client-group-form-modal.vue'),
  ),
  ClientGroupActivity: defineAsyncComponent(() =>
    import('frontend/client-group-activities/client-group-activity-form-modal.vue'),
  ),
  Cluster: defineAsyncComponent(() => import('frontend/clusters/cluster-form-modal.vue')),
  Driver: defineAsyncComponent(() => import('frontend/drivers/driver-form-modal.vue')),
  DriverSource: defineAsyncComponent(() =>
    import('frontend/driver-sources/driver-source-form-modal.vue'),
  ),
  CustomLocationMapPicker: defineAsyncComponent(() =>
    import('frontend/locations/custom-location-map-picker-modal.vue'),
  ),
  Location: defineAsyncComponent(() => import('frontend/locations/location-form-modal.vue')),
  BulkDurationRulesWizzard: defineAsyncComponent(() =>
    import(
      'frontend/origin-x-destination-matrix/duration-rules-wizzard/bulk-duration-rules-wizzard.vue'
    ),
  ),
  DurationRulesWizzard: defineAsyncComponent(() =>
    import(
      'frontend/origin-x-destination-matrix/duration-rules-wizzard/duration-rules-wizzard.vue'
    ),
  ),
  OslVRule: defineAsyncComponent(() => import('frontend/osls/v-rules/osl-v-rule-form-modal.vue')),
  OslDRule: defineAsyncComponent(() => import('frontend/osls/d-rules/osl-d-rule-form-modal.vue')),
  OslTbRule: defineAsyncComponent(() =>
    import('frontend/osls/tb-rules/osl-tb-rule-form-modal.vue'),
  ),
  TransportationPoint: defineAsyncComponent(() =>
    import('frontend/transportation-points/transportation-point-form-modal.vue'),
  ),
  TransportBuffer: defineAsyncComponent(() =>
    import('frontend/transport-buffers/transport-buffer-form-modal.vue'),
  ),
  Depot: defineAsyncComponent(() => import('frontend/depots/depot-form-modal.vue')),
  Vehicle: defineAsyncComponent(() => import('frontend/vehicles/vehicle-form-modal.vue')),
  VehicleSource: defineAsyncComponent(() =>
    import('frontend/vehicle-sources/vehicle-source-form-modal.vue'),
  ),
  TrackerDevice: defineAsyncComponent(() =>
    import('frontend/tracker-devices/tracker-device-form-modal.vue'),
  ),
  TrackingService: defineAsyncComponent(() =>
    import('frontend/tracking-services/tracking-service-form-modal.vue'),
  ),
  TransportBooking: defineAsyncComponent(() =>
    import('frontend/transport-bookings/transport-booking-form-modal.vue'),
  ),
  TransportBookingGroupingRule: defineAsyncComponent(() =>
    import(
      'frontend/transport-booking-grouping-rules/transport-booking-grouping-rule-form-modal.vue'
    ),
  ),
  ClientCommunicationRule: defineAsyncComponent(() =>
    import('frontend/client-communication-rules/client-communication-rule-form-modal.vue'),
  ),
  ClientCommunicationBlacklist: defineAsyncComponent(() =>
    import('frontend/client-communication-rules/client-communication-blacklist-modal.vue'),
  ),
  TransportBookingProvideOSL: defineAsyncComponent(() =>
    import('frontend/transport-bookings/transport-booking-provide-osl-modal.vue'),
  ),
  TransportBookingOverrideLeadLoadTime: defineAsyncComponent(() =>
    import('frontend/transport-bookings/transport-booking-override-lead-load-time-modal.vue'),
  ),
  TransportBookingChangeTransportationPoints: defineAsyncComponent(() =>
    import('frontend/transport-bookings/transport-booking-change-transportation-points-modal.vue'),
  ),
  BatchOnsiteRequestProvideOSL: defineAsyncComponent(() =>
    import('frontend/batch-onsite-requests/batch-onsite-request-provide-osl-modal.vue'),
  ),
  TransportBookingProvideTptComment: defineAsyncComponent(() =>
    import('frontend/transport-bookings/transport-booking-provide-tpt-comment-modal.vue'),
  ),
  BatchOnsiteRequestProvideTptComment: defineAsyncComponent(() =>
    import('frontend/batch-onsite-requests/batch-onsite-request-provide-tpt-comment-modal.vue'),
  ),
  BatchOnsiteRequestChangeTransportationPoints: defineAsyncComponent(() =>
    import(
      'frontend/batch-onsite-requests/batch-onsite-request-change-transportation-points-modal.vue'
    ),
  ),
  OrganizationUser: defineAsyncComponent(() =>
    import('frontend/organization-users/organization-user-form-modal.vue'),
  ),
  VehicleImport: defineAsyncComponent(() =>
    import('frontend/vehicle-imports/vehicle-import-form-modal.vue'),
  ),
  DriverImport: defineAsyncComponent(() =>
    import('frontend/driver-imports/driver-import-form-modal.vue'),
  ),
  ClientGroupActivityImport: defineAsyncComponent(() =>
    import('frontend/client-group-activity-imports/client-group-activity-import-form-modal.vue'),
  ),
  ClientImport: defineAsyncComponent(() =>
    import('frontend/client-imports/client-import-form-modal.vue'),
  ),
  ShuttleServiceImport: defineAsyncComponent(() =>
    import('frontend/shuttle-service-imports/shuttle-service-import-form-modal.vue'),
  ),
  TransportationPointImport: defineAsyncComponent(() =>
    import('frontend/transportation-point-imports/transportation-point-import-form-modal.vue'),
  ),
  EventOrganizationUserImport: defineAsyncComponent(() =>
    import(
      'frontend/event-organization-user-imports/event-organization-user-import-form-modal.vue'
    ),
  ),
  TransportBookingImport: defineAsyncComponent(() =>
    import('frontend/transport-booking-imports/transport-booking-import-form-modal.vue'),
  ),
  PrepareClientsForTemplate: defineAsyncComponent(() =>
    import('frontend/transport-booking-imports/prepare-clients-for-template-modal.vue'),
  ),
  CalendarEntry: defineAsyncComponent(() =>
    import('frontend/calendar-entries/calendar-entry-form-modal.vue'),
  ),

  OperationalPeriodWizzard: defineAsyncComponent(() =>
    import(
      'frontend/calendar-entries/cluster-calendar-entries/operational-periods-wizzard-modal.vue'
    ),
  ),
  SubfleetWizzard: defineAsyncComponent(() =>
    import('frontend/calendar-entries/staging-area-calendar-entries/subfleet-wizzard-modal.vue'),
  ),
  MinimumVehicleAllotmentWizzard: defineAsyncComponent(() =>
    import(
      'frontend/calendar-entries/staging-area-calendar-entries/minimum-vehicle-allotment-wizzard-modal.vue'
    ),
  ),
  WelcomeDeskWorkingHoursWizzard: defineAsyncComponent(() =>
    import(
      'frontend/calendar-entries/staging-area-calendar-entries/welcome-desk-working-hours-wizzard-modal.vue'
    ),
  ),
  DriverAvailabilityWizzard: defineAsyncComponent(() =>
    import(
      'frontend/calendar-entries/driver-calendar-entries/driver-availability-wizzard-modal.vue'
    ),
  ),
  DriverUnavailabilityWizzard: defineAsyncComponent(() =>
    import(
      'frontend/calendar-entries/driver-calendar-entries/driver-unavailability-wizzard-modal.vue'
    ),
  ),
  ShuttleTransfer: defineAsyncComponent(() =>
    import('frontend/shuttle-transfers/shuttle-transfer-form-modal.vue'),
  ),
  ShuttleServiceWizzard: defineAsyncComponent(() =>
    import('frontend/shuttle-services/shuttle-service-wizzard.vue'),
  ),
  ShuttleServiceGenerateLegs: defineAsyncComponent(() =>
    import('frontend/shuttle-services/shuttle-service-generate-legs-modal.vue'),
  ),
  DurationRule: defineAsyncComponent(() =>
    import('frontend/origin-x-destination-matrix/duration-rule-form-modal.vue'),
  ),
  DefaultDurationRule: defineAsyncComponent(() =>
    import('frontend/origin-x-destination-matrix/default-duration-rule-form-modal.vue'),
  ),
  DurationAutoRecalculationConfig: defineAsyncComponent(() =>
    import('frontend/origin-x-destination-matrix/duration-auto-recalculation-config-modal.vue'),
  ),

  // passenger module
  PassengerTransportBooking: defineAsyncComponent(() =>
    import('passenger_portal/demands/transport-booking-form-modal.vue'),
  ),
  PassengerBatchOnsiteRequest: defineAsyncComponent(() =>
    import('passenger_portal/demands/batch-onsite-request-form-modal.vue'),
  ),
  ChangePassword: defineAsyncComponent(() => import('frontend/session/change-password-modal.vue')),
  ClientCommunicationMessageResend: defineAsyncComponent(() =>
    import('frontend/client-communication-messages/client-communication-message-resend-modal.vue'),
  ),
  DriverRegistrationQrToken: defineAsyncComponent(() =>
    import('frontend/driver-registration-qr-tokens/driver-registration-qr-token-form-modal.vue'),
  ),
}

const PREVIEW_MODALS = {
  RoleValuePresets: defineAsyncComponent(() =>
    import('frontend/role-value-presets/role-value-presets-modal.vue'),
  ),
  ReportsTableModal: defineAsyncComponent(() => import('frontend/reports/reports-table-modal.vue')),
  ShiftAction: defineAsyncComponent(() => import('frontend/shift-actions/shift-action-modal.vue')),
  ActivityTransfer: defineAsyncComponent(() =>
    import('frontend/activity-transfers/activity-transfer-modal.vue'),
  ),
  Asl: defineAsyncComponent(() => import('frontend/asls/asl-modal.vue')),
  BatchOnsiteRequest: defineAsyncComponent(() =>
    import('frontend/batch-onsite-requests/batch-onsite-request-modal.vue'),
  ),
  Client: defineAsyncComponent(() => import('frontend/clients/client-modal.vue')),
  ClientCommunicationRule: defineAsyncComponent(() =>
    import('frontend/client-communication-rules/client-communication-rule-modal.vue'),
  ),
  ClientCommunicationRulePreview: defineAsyncComponent(() =>
    import('frontend/client-communication-rules/client-communication-rule-preview-modal.vue'),
  ),
  ClientCommunicationMessage: defineAsyncComponent(() =>
    import('frontend/client-communication-messages/client-communication-message-modal.vue'),
  ),
  ClientGroup: defineAsyncComponent(() => import('frontend/client-groups/client-group-modal.vue')),
  ClientGroupActivity: defineAsyncComponent(() =>
    import('frontend/client-group-activities/client-group-activity-modal.vue'),
  ),
  ClientCategory: defineAsyncComponent(() =>
    import('frontend/client-categories/client-category-modal.vue'),
  ),
  ClientFunction: defineAsyncComponent(() =>
    import('frontend/client-functions/client-function-modal.vue'),
  ),
  ClientFunctionRemark: defineAsyncComponent(() =>
    import('frontend/client-function-remarks/client-function-remark-modal.vue'),
  ),
  Cluster: defineAsyncComponent(() => import('frontend/clusters/cluster-modal.vue')),
  ClusterCalendar: defineAsyncComponent(() =>
    import('frontend/clusters/cluster-calendar-modal.vue'),
  ),
  TravelDurationPreview: defineAsyncComponent(() =>
    import(
      'frontend/origin-x-destination-matrix/connection-duration-preview/travel-duration-preview-modal.vue'
    ),
  ),
  DayLabel: defineAsyncComponent(() => import('frontend/day-labels/day-label-modal.vue')),
  Driver: defineAsyncComponent(() => import('frontend/drivers/driver-modal.vue')),
  DriverCategory: defineAsyncComponent(() =>
    import('frontend/driver-categories/driver-category-modal.vue'),
  ),
  DriverSource: defineAsyncComponent(() =>
    import('frontend/driver-sources/driver-source-modal.vue'),
  ),
  DriverRegistrationQrToken: defineAsyncComponent(() =>
    import('frontend/driver-registration-qr-tokens/driver-registration-qr-token-view-modal.vue'),
  ),
  DriverRegistrationRequest: defineAsyncComponent(() =>
    import('frontend/driver-registration-requests/driver-registration-request-view-modal.vue'),
  ),
  DurationRules: defineAsyncComponent(() =>
    import('frontend/origin-x-destination-matrix/duration-rules-preview-modal.vue'),
  ),
  Location: defineAsyncComponent(() => import('frontend/locations/location-modal.vue')),
  LocationType: defineAsyncComponent(() =>
    import('frontend/location-types/location-type-modal.vue'),
  ),
  Tsl: defineAsyncComponent(() => import('frontend/tsls/tsl-modal.vue')),
  Depot: defineAsyncComponent(() => import('frontend/depots/depot-modal.vue')),
  DriverCalendar: defineAsyncComponent(() => import('frontend/drivers/driver-calendar-modal.vue')),
  Osl: defineAsyncComponent(() => import('frontend/osls/osl-modal.vue')),
  OslVRule: defineAsyncComponent(() => import('frontend/osls/v-rules/osl-v-rule-modal.vue')),
  OslDRule: defineAsyncComponent(() => import('frontend/osls/d-rules/osl-d-rule-modal.vue')),
  OslTbRule: defineAsyncComponent(() => import('frontend/osls/tb-rules/osl-tb-rule-modal.vue')),
  StagingArea: defineAsyncComponent(() => import('frontend/staging-areas/staging-area-modal.vue')),
  Shift: defineAsyncComponent(() => import('frontend/shifts/shift-modal.vue')),
  TransportationPoint: defineAsyncComponent(() =>
    import('frontend/transportation-points/transportation-point-modal.vue'),
  ),
  TransferGroup: defineAsyncComponent(() =>
    import('frontend/transfer-groups/transfer-group-modal.vue'),
  ),
  TransportBooking: defineAsyncComponent(() =>
    import('frontend/transport-bookings/transport-booking-modal.vue'),
  ),
  Vehicle: defineAsyncComponent(() => import('frontend/vehicles/vehicle-modal.vue')),
  VehicleModel: defineAsyncComponent(() =>
    import('frontend/vehicle-models/vehicle-model-modal.vue'),
  ),
  VehicleBrand: defineAsyncComponent(() =>
    import('frontend/vehicle-brands/vehicle-brand-modal.vue'),
  ),
  VehicleType: defineAsyncComponent(() => import('frontend/vehicle-types/vehicle-type-modal.vue')),
  VehicleCategory: defineAsyncComponent(() =>
    import('frontend/vehicle-categories/vehicle-category-modal.vue'),
  ),
  VehicleSource: defineAsyncComponent(() =>
    import('frontend/vehicle-sources/vehicle-source-modal.vue'),
  ),
  SystemNotification: defineAsyncComponent(() =>
    import('frontend/system-notifications/system-notification-modal.vue'),
  ),
  ShuttleTransfer: defineAsyncComponent(() =>
    import('frontend/shuttle-transfers/shuttle-transfer-modal.vue'),
  ),
  ShuttleService: defineAsyncComponent(() =>
    import('frontend/shuttle-services/shuttle-service-modal.vue'),
  ),
  MapPreview: defineAsyncComponent(() => import('passenger_portal/demands/map-preview-modal.vue')),
  Version: defineAsyncComponent(() => import('frontend/versions/version-modal.vue')),
}

function useModals() {
  const previewModals = shallowRef(PREVIEW_MODALS)
  const formModals = shallowRef(FORM_MODALS)

  return { previewModals, formModals }
}

export { FORM_MODALS, PREVIEW_MODALS, useModals }
